'use client';

import { datadogLogs } from '@datadog/browser-logs';
import useLocalStorage from 'hooks/useLocalStorage';
import { useRouter } from 'navigation';
import { FC, ReactNode, createContext, useContext } from 'react';
import { getProperty } from 'dot-prop';

const insurance_flow = [
  '/onboarding/[screenId]/insurance/options',
  '/onboarding/[screenId]/insurance/primary',
  '/onboarding/[screenId]/payment',
  '/onboarding/[screenId]/address',
];

const schedule_flow = [
  '/onboarding/[screenId]/adhd/schedule',
  '/onboarding/[screenId]/appointment',
];

const flowMap: Record<string, any> = {
  schedule: {
    appointment: ['/onboarding/[screenId]/appointment/[typeId]'],
    confirmed: ['/onboarding/[screenId]/adhd/confirmed'],
    medication: [
      '/onboarding/[screenId]/adhd/medication',
      '/medication/confirmed',
      '/onboarding/[screenId]/adhd/medication_follow_up',
      '/medication_follow_up/confirmed',
    ],
  },
  appointment: {
    reschedule: ['/appointments/confirmed'],
  },
  family: {
    add_child: ['/onboarding/add-child'],
  },
  medication: [
    '/medication/choose-care',
    '/medication/[serviceId]/overview',
    '/medication/[serviceId]/appointment',
    '/medication/confirmed',
  ],
  pediatrician_referral: [
    '/referral',
    '/referral/clinic',
    '/referral/caregiver',
    '/referral/patient',
    '/referral/diagnosis',
    '/referral/behaviors',
    '/referral/complete',
  ],
  onboarding: {
    adhd: {
      intake: [
        '/onboarding/[screenId]/adhd',
        '/onboarding/[screenId]/adhd/parent-vanderbilt',
        '/onboarding/[screenId]/adhd/parent-vanderbilt/form',
        '/onboarding/[screenId]/adhd/teacher-vanderbilt',
        '/onboarding/[screenId]/adhd/teacher-vanderbilt/form',
        ...insurance_flow,
        ...schedule_flow,
      ],
      pc: [
        '/onboarding/[screenId]/adhd',
        '/onboarding/[screenId]/adhd/diagnosis-adhd-upload',
        '/onboarding/[screenId]/adhd/diagnosis-asd',
        '/onboarding/[screenId]/adhd/diagnosis-asd-upload',
        ...insurance_flow,
        ...schedule_flow,
      ],
      referral_dx: ['/onboarding/[screenId]/adhd', ...insurance_flow, ...schedule_flow],
      registration: [
        '/adhd',
        '/adhd/child-name',
        '/adhd/child-age',
        '/adhd/state',
        '/adhd/referral-source',
        '/adhd/eligible-success',
        '/auth/signup',
        '/auth/verify-request',
      ],
      self_referral: [
        '/onboarding/add-child',
        '/onboarding/[screenId]/adhd/behaviors',
        '/onboarding/[screenId]/referral',
        '/onboarding/[screenId]/adhd/diagnosis-adhd',
        '/onboarding/[screenId]/provider',
        '/onboarding/[screenId]/adhd',
      ],
      teens: {
        self_referral: [
          '/onboarding/add-child',
          '/onboarding/[screenId]/adhd/teens/behaviors',
          '/onboarding/[screenId]/adhd/teens/behaviors2',
          '/onboarding/[screenId]/referral',
          '/onboarding/[screenId]/adhd/diagnosis-adhd',
          '/onboarding/[screenId]/provider',
          '/onboarding/[screenId]/adhd',
        ],
        referral_dx: ['/onboarding/[screenId]/adhd', ...insurance_flow, ...schedule_flow],
        intake: ['/onboarding/[screenId]/adhd', ...insurance_flow, ...schedule_flow],
        pc: [
          '/onboarding/[screenId]/adhd',
          '/onboarding/[screenId]/adhd/diagnosis-adhd-upload',
          '/onboarding/[screenId]/adhd/diagnosis-asd',
          '/onboarding/[screenId]/adhd/diagnosis-asd-upload',
          ...insurance_flow,
          ...schedule_flow,
        ],
      },
    },
    forms: ['/onboarding/[screenId]/forms', '/dashboard'],
  },
};

type ContextProps = {
  navstate?: any;
  setFlow: any;
  appendFlow: any;
  clearFlow: any;
  getIndex: any;
  getNumSteps: any;
  goToNextPage: any;
  goToPrevPage: any;
  goToHomePage: any;
  insertPage: any;
  insertPageAfter: any;
  replaceRoute: any;
  appendRoute: any;
  removeRoute: any;
};

const NavigationContext = createContext<ContextProps>({
  navstate: null,
  setFlow: () => {},
  appendFlow: () => {},
  clearFlow: () => {},
  getIndex: () => 0,
  getNumSteps: () => 0,
  goToNextPage: () => {},
  goToPrevPage: () => {},
  goToHomePage: () => {},
  insertPage: () => {},
  insertPageAfter: () => {},
  replaceRoute: () => {},
  appendRoute: () => {},
  removeRoute: () => {},
});

type Props = {
  children: ReactNode;
};

const NavigationProvider: FC<Props> = ({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) => {
  const router = useRouter();

  const [_, setCurrentState, clearState, getState] = useLocalStorage<Record<string, any>>(
    'clarity:navstate',
    {},
  );

  const setFlow = (key: string, params?: Record<string, string>) => {
    const flow = getProperty(flowMap, key) ?? [];
    const updatedFlow = flow.map((path: string) => {
      if (params) {
        Object.keys(params).forEach((param) => {
          if (params[param]) {
            path = path.replace(`[${param}]`, params[param]);
          }
        });
      }
      return path;
    });

    setCurrentState({
      key,
      flow: updatedFlow,
    });

    return updatedFlow;
  };

  const appendFlow = (key: string, params?: Record<string, string>) => {
    const navstate = getState();

    if (!navstate?.flow) {
      return;
    }

    const newFlow = getProperty(flowMap, key) ?? [];
    const updatedFlow = newFlow.map((path: string) => {
      if (params) {
        Object.keys(params).forEach((param) => {
          if (params[param]) {
            path = path.replace(`[${param}]`, params[param]);
          }
        });
      }
      return path;
    });

    setCurrentState({
      key: navstate.key,
      flow: [...navstate.flow, ...updatedFlow],
    });

    return updatedFlow;
  };

  const insertPage = (next: string, idx: number) => {
    const navstate = getState();
    if (!navstate?.flow) {
      return;
    }

    navstate.flow.splice(idx, 0, next);
    setCurrentState(navstate);
  };

  const insertPageAfter = (next: string, route: string) => {
    const navstate = getState();
    if (!navstate?.flow) {
      return;
    }

    const idx = navstate.flow.indexOf(route);
    navstate.flow.splice(idx + 1, 0, next);
    setCurrentState(navstate);
  };

  const getIndex = () => {
    const navstate = getState();
    if (navstate?.flow) {
      const idx = navstate.flow.findIndex((path: string) => {
        return `${window.location.pathname}${window.location.search}`.endsWith(path);
      });

      return idx;
    }

    return -1;
  };

  const getNumSteps = () => {
    const navstate = getState();
    if (navstate?.flow) {
      return navstate.flow.length;
    }

    return 0;
  };

  const goToNextPage = (count?: number) => {
    const navstate = getState();
    if (!navstate?.flow) {
      router.refresh();
      return;
    }

    let idx = getIndex();
    idx += typeof count === 'number' ? count : 1;

    const nextPath = navstate.flow[idx];

    if (nextPath) {
      router.replace(nextPath);
    } else {
      datadogLogs.logger.warn('No next path found', {
        flow: navstate.flow,
        idx,
      });
      router.replace('/');
    }
  };

  const goToPrevPage = (count?: number) => {
    const navstate = getState();
    if (!navstate?.flow) {
      router.refresh();
      return;
    }

    let idx = getIndex();
    idx -= typeof count === 'number' ? count : 1;

    const prevPath = navstate.flow[idx];

    if (prevPath) {
      router.replace(prevPath);
    } else {
      datadogLogs.logger.warn('No prev path found', {
        flow: navstate.flow,
        idx,
      });
      router.replace('/');
    }
  };

  const replaceRoute = (path: string, replaceWith: string) => {
    const navstate = getState();
    if (navstate?.flow) {
      const idx = navstate.flow.indexOf(path);
      if (idx !== -1) {
        navstate.flow[idx] = replaceWith;
        setCurrentState(navstate);
      }
    }
  };

  const appendRoute = (path: string) => {
    const navstate = getState();
    if (navstate?.flow) {
      navstate.flow.push(path);
      setCurrentState(navstate);
    }
  };

  const removeRoute = (index: number) => {
    const navstate = getState();
    if (navstate?.flow) {
      navstate.flow.splice(index, 1);
      setCurrentState(navstate);
    }
  };

  const goToHomePage = () => {
    router.replace('/');
  };

  return (
    <NavigationContext.Provider
      value={{
        setFlow,
        appendFlow,
        clearFlow: clearState,
        getIndex,
        getNumSteps,
        goToNextPage,
        goToPrevPage,
        goToHomePage,
        insertPage,
        insertPageAfter,
        replaceRoute,
        appendRoute,
        removeRoute,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

const useNavigationContext = () => useContext(NavigationContext);

export { NavigationContext, NavigationProvider, useNavigationContext };
