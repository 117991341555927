'use client';

import { sendGTMEvent, GoogleTagManager } from '@next/third-parties/google';
import { ReactNode, useEffect, useState } from 'react';
import { usePathname } from 'navigation';
import { useSearchParams } from 'next/navigation';

type Props = {
  children: ReactNode;
};

const AnalyticsProvider = ({ children }: Props) => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const utm_source = searchParams?.get('utm_source');
  const utm_medium = searchParams?.get('utm_medium');
  const utm_campaign = searchParams?.get('utm_campaign');
  const utm_content = searchParams?.get('utm_content');
  const utm_term = searchParams?.get('utm_term');

  // Solution: https://github.com/vercel/next.js/issues/4662#issuecomment-908684049
  // another solution: https://github.com/vercel/next.js/issues/11639#issuecomment-1219654377
  const [initialRouteTracked, setInitialRouteTracked] = useState(false);

  if (
    typeof window !== 'undefined' &&
    !initialRouteTracked &&
    window.location.search === '' &&
    window.performance.getEntriesByType &&
    window.performance.getEntriesByType('navigation').length > 0 &&
    (window.performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming).type !==
      'reload'
  ) {
    setInitialRouteTracked(true);
    sendGTMEvent({
      event: 'gtm.js',
      page: pathname,
      utm: {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
      },
    });
  }

  useEffect(() => {
    sendGTMEvent({
      event: 'gtm.js',
      page: pathname,
      utm: {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_content,
        utm_term,
      },
    });
  }, [pathname, utm_source, utm_medium, utm_campaign, utm_content, utm_term]);

  return (
    <>
      {process.env.NEXT_PUBLIC_GTM_ID && (
        <GoogleTagManager gtmId={process.env.NEXT_PUBLIC_GTM_ID} />
      )}
      {children}
    </>
  );
};

export default AnalyticsProvider;
