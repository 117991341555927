import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';

type FormFieldProps = {
  label?: string | ReactNode;
  children: ReactNode;
  error?: any;
  align?: 'left' | 'center' | 'right';
  width?: string;
};

const FormField = (props: FormFieldProps) => (
  <Stack spacing={1} sx={{ width: props.width || '100%' }}>
    {props.label && (
      <Typography variant="body1" align={props.align}>
        {props.label}
      </Typography>
    )}
    {props.children}
    <Typography variant="inherit" color="error" sx={{ fontSize: '0.75rem' }}>
      {props.error?.message?.toString()}
    </Typography>
  </Stack>
);

export default FormField;
