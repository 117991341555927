// Necessary if using App Router to ensure this file runs on the client
'use client';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const ignoreErrors = [
  "Can't find variable: fbq",
  'fbq is not defined',
  'An error occurred in the Server Components render',
  'Fetch error GET',
  'XHR error POST',
  'Uncaught "Object Not Found Matching',
  'Minified React error',
  'NetworkError when attempting to fetch resource.',
  'Load failed',
];

if (
  process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production' &&
  process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN &&
  process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID
) {
  datadogRum.init({
    applicationId: process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID,
    clientToken: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'web-app',
    env: process.env.NEXT_PUBLIC_DEPLOY_ENV,
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: false,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: ['https://app.claritypediatrics.com'],
    beforeSend: (event) => {
      if (event.type === 'error' && ignoreErrors.some((err) => event.error.message.includes(err))) {
        return false;
      }

      return true;
    },
  });
}

if (
  (process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production' ||
    process.env.NEXT_PUBLIC_DEPLOY_ENV === 'staging') &&
  process.env.NEXT_PUBLIC_DD_LOG_CLIENT_TOKEN
) {
  datadogLogs.init({
    clientToken: process.env.NEXT_PUBLIC_DD_LOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'web-app',
    env: process.env.NEXT_PUBLIC_DEPLOY_ENV,
    version: process.env.NEXT_PUBLIC_APP_VERSION,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    beforeSend: (log) => {
      if (ignoreErrors.some((err) => log.message.includes(err))) {
        return false;
      }

      return true;
    },
  });
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
