'use client';

import { ZENDESK_CONVERSATION_FIELDS, ZENDESK_SRC_URL } from '@cp/shared/lib/constants';

export const zdCustomFields = async () => {
  const conversationFields = Object.values(ZENDESK_CONVERSATION_FIELDS).map((id) => ({
    id,
    value: document.getElementById(`${id}`)?.innerHTML,
  }));

  await zdSet(['conversationFields', conversationFields]);
};

const zdSubscribe = async (openCb: () => void, closedCb: () => void) => {
  const { zE } = window;

  if (zE) {
    await zE('messenger:on', 'open', openCb);
    await zE('messenger:on', 'close', closedCb);
  }
};

const zdHide = async () => {
  await zdAction('hide');
};

const zdShow = async () => {
  await zdAction('show');
};

export const zdClose = async () => {
  await zdAction('close');
};

export const zdOpen = async () => {
  await zdAction('open');
};

const zdLocale = async (locale: string) => {
  await zdSet(['locale', locale]);
};

const zdSet = async (opts: any[]) => {
  const { zE } = window;

  await zdClose();
  if (zE) {
    zE('messenger:set', 'zIndex', 999999);
    zE('messenger:set', ...opts);
  }
};

const zdAction = async (action: string) => {
  const { zE } = window;

  if (zE) {
    await zE('messenger', action);
  }
};

const zdRemove = async () => {
  // My hope was by fully removing, then re-adding the script, that it would
  // reset and re `load` it after language change.  But it does not reload.
  // This code will fully remove the Zendesk pieces, afaict.  But there may
  // be more.  Leaving here in case we want to explore this more.  This gets
  // us part way there.
  await zdClose();
  await zdHide();
  const iframes = document.getElementsByTagName('iframe');
  const frameArr = Array.from(iframes);

  const button = document.getElementById('launcher');
  button?.remove();

  for (const frame of frameArr) {
    if (
      Array.from(frame.attributes).find(
        (att: any) => att.name === 'data-product' && att.nodeValue === 'web_widget',
      )
    ) {
      frame.remove();
    }
  }
  for (const frame of frameArr) {
    if (frame.title === 'Messaging window') {
      frame.remove();
    }
  }
  document.getElementById('ze-snippet')?.remove();
};

export const zdAdd = (onOpen: () => void, onClose: () => void, locale: string) => {
  if (!window.document?.getElementById('ze-snippet')) {
    const script = document.createElement('script');

    script.id = 'ze-snippet';
    script.src = ZENDESK_SRC_URL;
    script.onload = async () => {
      await zdCustomFields();
      await zdSubscribe(onOpen, onClose);
      await zdLocale(locale);
      await zdOpen();
    };

    document.body.appendChild(script);
  }
};
